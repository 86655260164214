$(document).on("change", "#matter-types", function (e) {
  $("#matter-subtype").prop("disabled", false)
  $("#matter-subtype").empty();
  $("#matter-subtype").append('<option value="">Loading....</option>');
  var parent_id = $(this).val(); // Get the selected parent ID

  $.ajax({
    url: myAjax.ajaxurl, // In WordPress, 'ajaxurl' is predefined
    type: "POST",
    data: {
      action: "fetch_child_matter_types", // Custom action name defined in PHP
      parent_id: parent_id, // Pass the selected parent ID
    },
    success: function (response) {
      console.log(response);
      if (response.success) {
        console.log("Child Terms:", response.data); // Display child terms in console or process them
        $("#matter-subtype").empty(); // Clear any previous options
        $("#matter-subtype").append(
          '<option value="">Select Matter Subtype</option>'
        );

        // Populate the child-select dropdown with the received terms
        $.each(response.data, function (index, term) {
          $("#matter-subtype").append(
            '<option value="' + term.slug + '">' + term.name + "</option>"
          );
        });
      } else {
        $("#matter-subtype").prop("disabled", true)
        console.log("Error:", response.data); // Handle errors
      }
    },
    error: function (jqXHR, textStatus, errorThrown) {
      $("#matter-subtype").empty();
      $("#matter-subtype").append(
        '<option value="">Select Matter Subtype</option>'
      );
      $("#matter-subtype").prop("disabled", true)
      console.log("AJAX Error:", textStatus, errorThrown); // Handle AJAX errors
    },
  });
});
